<template>
	<div class="about">
		<div class="about__info" id="box">
			<router-link to="/donation" class="back-btn">
				<span class="arrow"></span>
				Вернуться
			</router-link>
			<div class="about__title">
				Встреча <br>с идеальной парой
			</div>
			<div class="about__text">
				<p><strong>Вы сдали костный мозг и вернулись домой. Вашему генетическому близнецу выполнили трансплантацию. Но кто он, помогла ли процедура? Об этом вы узнаете постепенно.</strong></p>
				<p>О безвозмездности и добровольности донорства вы уже слышали. <br>Еще один важный принцип — анонимность. Она защищает обе стороны <br>от неблаговидных поступков, например от попыток надавить на жалость <br>со стороны родственников пациента или заработать на чужом горе <br>со стороны донора. Кроме того, она оберегает и вас, и вашу половинку <br>от взаимных поисков в соцсетях, чтобы знание пола, возраста, национальности, политических убеждений, материального положения <br>или чего‑то еще не стало поводом для отказа от донации.</p>
				<p>Однако в случае с донорством костного мозга анонимность постепенно снимается. До процедуры ни вы, ни ваша половинка ничего не знаете друг <br>о друге. Во время подготовки к донации вам сообщат пол и примерный возраст человека, чью жизнь вы спасаете. Вы узнаете, например, <br>что это «юноша 19 лет» или «женщина за 40 лет».</p>
				<p>Если у вас есть желание узнать, прижился ли ваш костный мозг, то через месяц после донации вы можете выяснить у сотрудника регистра, <br>как чувствует себя реципиент. Тогда же, если у вас и у вашей половинки <br>есть взаимное согласие, можно начать переписку. Правда, все равно придется соблюдать анонимность и не указывать никакой личной информации: места жительства, возраста и даже имени.</p>
				<p>Переписка будет происходить через врачей, которые в обязательном <br>порядке вскроют корреспонденцию и проверят, нет ли там данных, которые могут позволить раскрыть вам личность друг друга. Так будет продолжаться два года. И только когда они истекут, анонимность будет снята. После этого — если есть обоюдное желание — вы и ваша половинка можете встретиться онлайн или в реальности.</p>
				<p>Те люди, которые соглашаются на встречу, почти всегда отзываются <br>о ней как об опыте, который позволяет пересмотреть свои ценности. <br>Доноры начинают выше ценить каждую минуту в жизни, потому что через личную историю своего генетического близнеца узнают, что это значит — оказаться на грани. А для реципиентов это всегда возможность больше верить в добро и в хороших людей.</p>
				<p>Еще недавно онкологические заболевания крови были равносильны приговору. Сейчас они лечатся. И это чудо происходит благодаря донору. <br>Он отдает только небольшую часть своих клеток, тратит на все немного времени, но зато получает возможность спасти чью‑то жизнь и приобрести по‑настоящему близкого человека. Как говорят и доноры, и реципиенты, <br>они получают нового члена семьи, на которого могут теперь во всем положиться. Ведь вы и ваша половинка теперь одной крови.</p>
			</div>
			<div class="about__bottom">
				<router-link to="/miracle" class="about__link" @click.native="sendEvent('vstrecha_chudo')">СЛЕДУЮЩИЙ ШАГ: СОВЕРШИТЬ ЧУДО</router-link>
			</div>
		</div>
		<div class="about__interactive about__interactive--hands about__interactive--violet-2">
			<div class="hands__wrapper">
				<div class="hands">
					<img src="@/assets/img/hand-1-1.png" class="hands__1" alt="">
					<img src="@/assets/img/hand-2-1.png" class="hands__2" alt="">
				</div>
			</div>
			<div class="about__label">
				<span class="about__label-text">Верьте <br>в добро</span>
			</div>
		</div>
		<a href="javascript:void(0)" class="about__myth" @click="modal = true" :class="{scrolled: scrolled > 100, 'about__myth--violet': scrolled < 100, 'about__myth--pink': scrolled > 99}">
			<span class="about__myth-text"><span><strong>миф</strong>  <br>Быть донором можно только <br>один раз в жизни <br><strong class="underline">УЗНАТЬ БОЛЬШЕ</strong></span></span>
		</a>
		<Modal v-if="modal" @close="modal = false"></Modal>
	</div>
</template>

<script>
	import Modal from '@/components/Popup_6.vue'
	export default {
		name: "Step_6",
		data() {
			return {
				modal: false,
				ready: false,
				scrolled: 0,
			}
		},
		components: {
			Modal,
		},
		mounted() {
			var element = document.getElementById('box');
			element.addEventListener('scroll', () => {
				this.scrolled = element.scrollTop;
			})
			window.CI360.init();
			setTimeout(() => {
				this.ready = true;
			}, 500)
		}
	}
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/about.sass'
	.hands
		&__wrapper
			transform: rotate(35deg) translateX(0vw) translateY(-5vw)
			width: 100%
			height: 100%
		&__1
			width: vw(928px)
			height: auto
			position: absolute
			top: vw(362px)
			left: vw(-274px)
			transform: rotate(-35deg)
			animation: hand2
			animation-duration: 3s
			animation-timing-function: linear
			animation-iteration-count: infinite
		&__2
			width: vw(730px)
			height: auto
			position: absolute
			top: vw(-226px)
			right: vw(-216px)
			transform: rotate(-35deg)
			animation: hand1
			animation-duration: 3s
			animation-timing-function: linear
			animation-iteration-count: infinite
	@keyframes hand1
		0%
			top: vw(-226px)
			right: vw(-216px)
			transform: rotate(-35deg)
		50%
			top: vw(-185px)
			right: vw(-221px)
			transform: rotate(-31deg)
		70%
			top: vw(-185px)
			right: vw(-221px)
			transform: rotate(-31deg)
		100%
			top: vw(-226px)
			right: vw(-216px)
			transform: rotate(-35deg)
	@keyframes hand2
		0%
			top: vw(362px)
			transform: rotate(-35deg)
			left: vw(-274px)
		50%
			top: vw(340px)
			transform: rotate(-31.4deg)
			left: vw(-263px)
		70%
			top: vw(340px)
			transform: rotate(-31.4deg)
			left: vw(-263px)
		100%
			top: vw(362px)
			transform: rotate(-35deg)
			left: vw(-274px)
</style>