<template>
    <div class="page">
      <Header />
      <Finish />
    </div>
  </template>
  
  <script>
    import Header from '@/components/Header'
    import Finish from '@/components/Finish'
    export default {
      name: "Main",
      components: {
        Header,
        Finish,
      }
    }
  </script>