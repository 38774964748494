<template>
	<div class="hero">
		<div class="hero__animated hero__animated--desktop">
			<div
				class="cloudimage-360"
				data-folder="/BG_2/"
				data-index-zero-base="2"
				data-filename-x="desc_light_{index}.jpg"
				data-amount-x="39"
				data-autoplay
			></div>
		</div>
		<div class="hero__animated hero__animated--mobile">
			<div
				class="cloudimage-360"
				data-folder="/BG_2_MOB/"
				data-index-zero-base="2"
				data-filename-x="light_mob_{index}.jpg"
				data-amount-x="39"
				data-autoplay
			></div>
		</div>
		<div class="container">
			<div class="hero__content">
				<div class="hero__head">
					<div class="hero__title hero__title--1">ИДЕАЛЬНОЕ</div>
					<div class="hero__title hero__title--2">СОВПАДЕНИЕ</div>
				</div>
				<div class="hero__text">На то, чтобы встретить по‑настоящему своего человека, <br>нередко уходят годы. Но иногда у людей нет этого времени — <br>если они страдают от заболевания, требующего пересадки костного мозга. Шанс, что найдется идеальная пара, то есть донор <br>с совпадающим HLA-генотипом, составляет 1 на 100 000 человек. <br>И этим донором можете быть именно вы.</div>
				<div class="hero__button">
					<router-link to="/solution" class="hero__button-link" @click.native="sendEvent('btn_put')">Узнайте, какой вас ждет путь</router-link>
				</div>
				<div class="hero__note">Социальная реклама. Рекламодатель ФГБУ «НМИЦ гематологии» <br>Минздрава России <a href="https://blood.ru/" target="_blank">blood.ru</a></div>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Start",
	mounted() {
		window.CI360.init();
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/hero.sass'
</style>
