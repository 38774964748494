<template>
	<div class="preloader">
		<div class="loader_wrapper">
			<div class="elem_1">
				<svg width="134" height="160" viewBox="0 0 134 160" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M133.67 125.282L92.5199 84.1318L118.52 58.1314L60.537 0.152832L0 60.6946L99.3057 160" fill="#554596" />
				</svg>
			</div>
			<div class="elem_2">
				<svg width="95" height="121" viewBox="0 0 95 121" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M25.9109 60.3648L0.0252686 86.2505L34.5379 120.763L94.941 60.3648L60.4236 25.8522L34.5714 0L0.173513 34.3979L0.140035 34.3644L0.0252686 34.4792L25.9109 60.3648Z" fill="#E6007E" />
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
	name: "Preloader",
	}
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	.preloader
		width: 100vw
		height: 100vh
		background: radial-gradient(157.71% 144.64% at 21.34% 25.5%, #FFE6F3 0%, #FF95CF 100%)
		display: flex
		justify-content: center
		align-items: center
		position: fixed
		left: 0
		top: 0
		z-index: 1000
	.loader_wrapper 
		width: 200px
		height: 200px
		position: relative
		@media screen and (max-width: 1000px)
			width: 200px
			height: 200px
	.loader_wrapper div 
		display: block
		position: absolute
	.elem_1 
		left: 2px
		top: 2px
		animation: 2s left_anim 0s infinite
		animation-delay: 1s
	.elem_2 
		right: 2px
		animation: 2s right_anim 1s infinite

	@keyframes left_anim 
		0% 

		25% 
			transform: scale(90%, 90%) translate(-10px, 5px)

		50% 
			transform: scale(120%, 120%) translate(-10px, 5px)
		
		100% 
	
	@keyframes right_anim 
		0%

		25% 
			transform: scale(120%, 120%) translate(10px, 0px)
		
		50% 
			transform: scale(120%, 120%) translate(10px, 0px)
		
		100% 
</style>