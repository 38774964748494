<template>
	<div class="popup">
		<div class="popup__bg"></div>
		<div class="popup__window popup__window--pink">
			<a href="javascript:void(0)" @click="$emit('close')" class="popup__close"></a>
			<div class="popup__title"><strong>Существует миф, что</strong> <br>костный мозг находится <br>в позвоночнике</div>
			<div class="popup__text">Спорим, что как только вы прочли о донорстве костного мозга, то представили укол <br>в позвоночник? И вздрогнули от одной только мысли. Не пугайтесь: как и многие люди, <br>вы слегка запутались в терминах. В позвоночнике расположен спинной мозг — орган <br>центральной нервной системы, состоящий из нейронов. А костный мозг находится <br>внутри костей. В нем содержится много стволовых клеток, из которых формируются <br>все клетки крови в организме. Пересадка костного мозга позволяет заменить <br>дефектные клетки у больных людей, чтобы у них сформировалась новая иммунная <br>и кроветворная система. Чаще всего костный мозг требуется при лечении рака крови — <br>лейкоза и лимфомы. Для забора костного мозга не требуется делать укол <br>в позвоночник. И даже в кость необязательно колоть. Тогда откуда же берут стволовые <br>клетки? Об этом мы подробно расскажем на шаге о процедуре донации.</div>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Popup_1",
	mounted() {
		this.sendEvent('reshenie_mif');
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/popup.sass'
</style>