<template>
	<div class="popup">
		<div class="popup__bg"></div>
		<div class="popup__window popup__window--violet">
			<a href="javascript:void(0)" @click="$emit('close')" class="popup__close"></a>
			<div class="popup__title desk"><strong>Существует миф, что</strong> <br>Быть донором можно только <br>один раз в жизни</div>
			<div class="popup__title mob"><strong>Существует миф, что</strong> <br>Быть донором можно <br>только один раз в жизни</div>
			<div class="popup__text">На восстановление гемопоэтических стволовых клеток организму необходимо <br>от нескольких дней до месяца; по этой причине при необходимости можно стать <br>донором костного мозга несколько раз — причем для разных пациентов. Но с учетом <br>того, что вероятность совпасть с кем‑то составляет около 1%, многие люди, вступившие <br>в регистр, не становятся донорами никогда.</div>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Popup_6",
	mounted() {
		this.sendEvent('vstrecha_mif');
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/popup.sass'
</style>