<template>
	<div class="burger-menu">
		<div class="burger-menu__list">
			<div class="burger-menu__item">
				<router-link to="/solution" class="burger-menu__link" @click.native="open('m_reshenie')">Решение</router-link>
			</div>
			<div class="burger-menu__item">
				<router-link to="/type" class="burger-menu__link" @click.native="open('m_tipirovanie')">Типирование</router-link>
			</div>
			<div class="burger-menu__item">
				<router-link to="/search" class="burger-menu__link" @click.native="open('m_para')">Поиск пары</router-link>
			</div>
			<div class="burger-menu__item">
				<router-link to="/activation" class="burger-menu__link" @click.native="open('m_aktivacia')">Активация</router-link>
			</div>
			<div class="burger-menu__item">
				<router-link to="/donation" class="burger-menu__link" @click.native="open('m_donacia')">Донация</router-link>
			</div>
			<div class="burger-menu__item">
				<router-link to="/meet" class="burger-menu__link" @click.native="open('m_vstrecha')">Встреча</router-link>
			</div>
		</div>
		<div class="burger-menu__close" @click="$emit('close')"></div>
	</div>
</template>

<script>
  export default {
	name: "Burger_menu",
	methods: {
		open(name) {
			this.sendEvent(name)
			setTimeout(() => {
				this.$emit('close');
			}, 100)
		}
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	.burger-menu
		width: 100vw
		height: 100vh
		position: fixed
		left: 0
		top: 0
		background: radial-gradient(126.69% 117.32% at 41.72% 43.74%, #FFF 0%, #FFC7E6 97.4%)
		z-index: 90
		padding-top: vwm(121px)
		&__list
			text-align: center
			margin-bottom: vwm(62px)
		&__item
			margin-bottom: vwm(40px)
		&__link
			font-size: vwm(30px)
			font-weight: 600
			color: $violet
			font-family: 'Ysabeau'
			text-transform: uppercase
			letter-spacing: 0.6px
			text-decoration: none
			transition: all 300ms
			&:hover
				font-weight: 700
		&__close
			background-image: url(../assets/img/close-menu.svg)
			width: vwm(33px)
			height: vwm(33px)
			background-size: contain
			background-repeat: no-repeat
			margin: auto
			cursor: pointer
</style>