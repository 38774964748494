<template>
	<div class="about">
		<div class="about__info">
			<div class="about__info-top about__info-top--violet">
				<router-link to="/type" class="back-btn">
					<span class="arrow"></span>
					Вернуться
				</router-link>
				<div class="about__title">
					Идеальная пара найдена
				</div>
				<div class="about__text">
					<p><strong>Кажется, принять решение и сдать кровь <br>на типирование — это самый важный шаг. Но куда сложнее ждать и при этом <br>не терять мотивации.</strong></p>
					<p>Идеальное совпадение может случиться <br>как через несколько месяцев <br>после вступления в регистр, так и через пять, десять или даже двадцать лет. А может <br>не случиться никогда, потому что ваша идеальная генетическая половинка <br>не столкнется с онкогематологическим заболеванием.</p>
					<p>Предсказать время и вероятность совпадения невозможно, и при этом придется не только сообщать о переездах <br>и смене контактных данных, но и годами сохранять готовность стать донором. </p>
					<p>Пока вы ожидаете идеального совпадения, поддерживайте здоровый образ жизни, сбалансированно питайтесь, регулярно проходите профосмотры <br>и диспансеризацию. Это необходимо <br>для того, чтобы исключить развитие хронических заболеваний, которые <br>не позволят вам из потенциального донора превратиться в реального. </p>
					<p>Если однажды вам позвонят, то в этот момент надо принять окончательное решение, готовы ли вы спасти жизнь генетического близнеца. Возможно, вас будут отговаривать члены семьи, потому что будут считать <br>ваш поступок рискованным. Зачастую люди так считают, потому что у них мало информации о донорстве костного мозга или они не привыкли помогать другим безвозмездно.</p>
				</div>
				<div class="about__interactive about__interactive--violet">
					<div class="about__image">
						<div
							class="cloudimage-360"
							data-index-zero-base="2"
							data-folder="/TIME/"
							data-image-list-x='[
								"SandTime_00.png",
								"SandTime_01.png",
								"SandTime_02.png",
								"SandTime_03.png",
								"SandTime_04.png",
								"SandTime_05.png",
								"SandTime_06.png",
								"SandTime_07.png",
								"SandTime_08.png",
								"SandTime_09.png",
								"SandTime_10.png",
								"SandTime_11.png",
								"SandTime_12.png",
								"SandTime_13.png",
								"SandTime_14.png",
								"SandTime_15.png",
								"SandTime_16.png",
								"SandTime_17.png",
								"SandTime_18.png",
								"SandTime_19.png",
								"SandTime_20.png",
								"SandTime_21.png",
								"SandTime_22.png",
								"SandTime_23.png",
								"SandTime_24.png",
								"SandTime_25.png",
								"SandTime_26.png",
								"SandTime_27.png",
								"SandTime_28.png",
								"SandTime_29.png",
								"SandTime_29.png",
								"SandTime_28.png",
								"SandTime_27.png",
								"SandTime_26.png",
								"SandTime_25.png",
								"SandTime_24.png",
								"SandTime_23.png",
								"SandTime_22.png",
								"SandTime_21.png",
								"SandTime_20.png",
								"SandTime_19.png",
								"SandTime_18.png",
								"SandTime_17.png",
								"SandTime_16.png",
								"SandTime_15.png",
								"SandTime_14.png",
								"SandTime_13.png",
								"SandTime_12.png",
								"SandTime_11.png",
								"SandTime_10.png",
								"SandTime_09.png",
								"SandTime_08.png",
								"SandTime_07.png",
								"SandTime_06.png",
								"SandTime_05.png",
								"SandTime_04.png",
								"SandTime_03.png",
								"SandTime_02.png",
								"SandTime_01.png"
							]'
							data-autoplay
						></div>
					</div>
					<div class="about__label">
						<span class="about__label-text">важно подождать</span>
					</div>
				</div>
			</div>
			<div class="about__content">
				<div class="about__text">
					<p>Допустим, вы подтвердили согласие — и что, пора сразу ехать на трансплантацию? Нет, доноров отбирают почти так же строго, <br>как космонавтов. С вами пообщается специалист регистра, чтобы исключить возможные противопоказания, <br>а это беременность, период грудного вскармливания, острое или хроническое заболевание и другие факторы.</p>
					<p>После этого донору обычно предлагают пройти подтверждающее типирование. <br>На этом этапе врачи должны исключить любые ошибки: вдруг при повторном анализе совпадение окажется неполным. <br>И вот, результаты готовы. Поздравляем, <br>у вас идеальное совпадение!</p>
				</div>
				<a href="javascript:void(0)" class="about__myth about__myth--violet rellax-h" :class="{'go': scrolled > 70}" @click="modal = true" data-rellax-vertical-speed="0" data-rellax-horizontal-speed="-0.2" data-rellax-vertical-scroll-axis="x"  data-rellax-percentage="0.48">
					<span class="about__myth-text"><span><strong>миф</strong> <br>Доноры должны быть <br>совместимы по группе крови <br><strong class="underline">УЗНАТЬ БОЛЬШЕ</strong></span></span>
				</a>
				<div class="about__bottom">
					<div class="about__row step3">
						<div class="about__row-column">
							<div class="about__row-number">29 000</div>
							<div class="about__row-title">человек в россии</div>
							<div class="about__row-text">ежегодно заболевают <br>болезнями системы крови</div>
							<div class="about__row-image">
								<img src="@/assets/img/people.svg" alt="">
							</div>
						</div>
						<div class="about__row-column">
							<div class="about__row-column-block">
								<div class="about__row-number">1500</div>
								<div class="about__row-title">пациентов</div>
								<div class="about__row-text">ежегодно нуждаются в трансплантации <br>от неродственного донора</div>
							</div>
							<div class="about__row-column-block">
								<div class="about__row-column-row">
									<div class="about__row-number">0,1 <small>%</small></div>
									<div class="about__row-title">россиян</div>
								</div>
								<div class="about__row-text">состоят в регистре <br>неродственных доноров <br>костного мозга</div>
							</div>
						</div>
					</div>
					<router-link to="/activation" class="about__link" @click.native="sendEvent('para_proiti')"><span>СЛЕДУЮЩИЙ ШАГ: <br>Пройти обследование</span></router-link>
				</div>
			</div>
		</div>
		<Modal v-if="modal" @close="modal = false"></Modal>
	</div>
</template>

<script>
  import Modal from '@/components/Popup_3.vue'
  export default {
	name: "Step_mob_3",
	data() {
		return {
			modal: false,
			scrolled: 0,
		}
	},
	components: {
		Modal,
	},
	mounted() {
		window.CI360.init();
		document.addEventListener('scroll', () => {
			var h = document.documentElement, 
				b = document.body,
				st = 'scrollTop',
				sh = 'scrollHeight';

			this.scrolled = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
		})
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/about.sass'
</style>