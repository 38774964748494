<template>
	<div>
		<header class="header">
			<div class="container">
				<div class="header__content">
					<a href="https://km.donorstvo.org" target="_blank" class="header__logo header__logo--1" @click="sendEvent('logo_donorstvo')"></a>
					<a href="https://minzdrav.gov.ru" target="_blank" class="header__logo header__logo--2" @click="sendEvent('logo_minzdrav')"></a>
					<div class="header__menu">
						<div class="header__menu-item" @click="sendEvent('m_reshenie')">
							<router-link to="/solution" class="header__menu-link">Решение</router-link>
						</div>
						<div class="header__menu-item" @click="sendEvent('m_tipirovanie')">
							<router-link to="/type" class="header__menu-link">Типирование</router-link>
						</div>
						<div class="header__menu-item" @click="sendEvent('m_para')">
							<router-link to="/search" class="header__menu-link">Поиск пары</router-link>
						</div>
						<div class="header__menu-item" @click="sendEvent('m_aktivacia')">
							<router-link to="/activation" class="header__menu-link">Активация</router-link>
						</div>
						<div class="header__menu-item" @click="sendEvent('m_donacia')">
							<router-link to="/donation" class="header__menu-link">Донация</router-link>
						</div>
						<div class="header__menu-item" @click="sendEvent('m_vstrecha')">
							<router-link to="/meet" class="header__menu-link">Встреча</router-link>
						</div>
					</div>
					<a href="https://www.afisha.ru/" target="_blank" class="header__logo header__logo--3" @click="sendEvent('logo_afisha')"></a>
					<div class="header__burger" @click="burger = true">
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>
		</header>
		<Burger_menu v-if="burger" @close="burger = false"></Burger_menu>
	</div>
</template>

<script>
	import Burger_menu from '@/components/Burger_menu.vue'
  export default {
	name: "Header",
	data() {
		return {
			burger: false
		}
	},
	components: {
		Burger_menu,
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	.header
		background-color: #fff
		box-shadow: 0px 4px 10px 0px rgba(85, 69, 150, 0.20)
		position: fixed
		left: 0
		top: 0
		width: 100%
		z-index: 100
		&__content
			display: flex
			align-items: center
			margin-left: vw(-2px)
			padding: vw(14px) vw(2px) vw(10px) 0
			@media screen and (max-width: 1000px)
				padding: vwm(7px) 0 vwm(6px) 0
		&__logo
			display: inline-block
			background-size: contain
			background-repeat: no-repeat
			&--1
				width: vw(102px)
				height: vw(43px)
				background-image: url(../assets/img/logo-donor.svg)
				margin-right: vw(88px)
				@media screen and (max-width: 1000px)
					width: vwm(58px)
					height: vwm(25px)
					margin-right: vwm(20px)
			&--2
				width: vw(152px)
				height: vw(44px)
				background-image: url(../assets/img/logo-minzdrav.svg)
				@media screen and (max-width: 1000px)
					width: vwm(86px)
					height: vwm(25px)
					margin-right: vwm(21px)
			&--3
				width: vw(89px)
				height: vw(20px)
				background-image: url(../assets/img/afisha.svg)
				margin-left: auto
				margin-bottom: vw(4px)
				@media screen and (max-width: 1000px)
					width: vwm(63px)
					height: vwm(14px)
					margin-bottom: vwm(2px)
					margin-left: 0
		&__menu
			display: flex
			align-items: center
			margin-left: vw(109px)
			margin-bottom: vw(4px)
			@media screen and (max-width: 1000px)
				display: none
			&-item
				margin-right: vw(23.6px)
			&-link
				font-size: vw(14px)
				font-weight: 600
				text-transform: uppercase
				color: $violet
				font-family: 'Ysabeau'
				text-decoration: none
				letter-spacing: 0.28px
				transition: all 300ms
				&:hover	
					font-weight: 700
		&__burger
			display: none
			@media screen and (max-width: 1000px)
				display: flex
				flex-direction: column
				align-items: center
				justify-content: space-between
				width: vwm(37px)
				height: vwm(16px)
				margin-left: auto
				cursor: pointer
				span
					width: 100%
					height: vwm(2px)
					background-color: $violet
</style>