<template>
	<div class="popup">
		<div class="popup__bg"></div>
		<div class="popup__window popup__window--green">
			<a href="javascript:void(0)" @click="$emit('close')" class="popup__close"></a>
			<div class="popup__title desk"><strong>Существует миф, что</strong> <br>Восстановление после процедуры <br>донации длится месяцы</div>
			<div class="popup__title mob"><strong>Существует миф, что</strong> <br>Восстановление после <br>процедуры донации <br>длится месяцы</div>
			<div class="popup__text">Костный мозг ежедневно вырабатывает миллионы клеток крови — тромбоцитов, <br>лейкоцитов и эритроцитов. Во время донации берут в среднем 3% костного мозга, и этот <br>объем полностью восстанавливается в течение двух-трех недель. Процедура проходит <br>в стерильных условиях, поэтому исключает риски для здоровья.</div>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Popup_4",
	mounted() {
		this.sendEvent('activacia_mif');
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/popup.sass'
</style>