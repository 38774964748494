<template>
	<div class="about">
		<div class="about__info" id="box">
			<router-link to="/search" class="back-btn">
				<span class="arrow"></span>
				Вернуться
			</router-link>
			<div class="about__title">
				Активация донора
			</div>
			<div class="about__text">
				<p><strong>Идеальная пара найдена, но даже это не означает, что донация состоится. Хотя цель процедуры — спасти жизнь больного, у врачей в приоритете здоровье донора. Это значит, что после подтверждения совпадения <br>вас пригласят на комплексное обследование в центр заготовки. <br>Возможно, вам придется совершить путешествие через всю Россию.</strong></p>
				<p>Поездку, проживание и все медицинские процедуры оплатит благотворительный фонд или регистр. За сдачу костного мозга <br>вы не получите никаких денег, потому что безвозмездность — это один <br>из основных принципов донорства. Пациенту проведут пересадку <br>стволовых клеток бесплатно — процедура финансируется государством <br>по квоте высокотехнологичной медицинской помощи. </p>
				<p>Но вот поиск и активацию донора ваша половинка оплачивает сама <br>или с помощью благотворительного фонда. И это значительная сумма: активация донора из российского регистра в среднем стоит 310–450 тысяч рублей, а из международного регистра — от 23 тысяч евро. И это еще одна причина, почему важно вступать в регистр в России.</p>
				<p>Когда вы приедете в центр, вам проведут обследование. Оно включает анализы мочи и крови, электрокардиограмму, рентгенографию легких. <br>Кроме того, с донором обязательно общается врач, который объясняет <br>все нюансы предстоящей процедуры и отвечает на любые вопросы.</p>
				<p>Обычно обследование занимает один-два дня, после чего можно возвращаться домой и ждать результатов. Они становятся известны <br>в течение недели. Иногда, если врачей в них что‑то настораживает, может потребоваться повторный приезд в центр заготовки. Но чаще всего <br>во второй раз вы поедете уже непосредственно на процедуру донации.</p>
				<p>И вот тут для вашей идеальной половинки наступает решающий момент. <br>За 5–10 дней до трансплантации пациенту проводят высокодозную химиотерапию, которая полностью уничтожает его кроветворную и <br>иммунную системы. В этот момент уже нельзя отказаться от процедуры донорства, даже если произойдет какая‑то чрезвычайная ситуация. Просто потому что иначе человек погибнет.</p>
			</div>
			<div class="about__bottom">
				<div class="about__row-title">ПОЧЕМУ ВАЖНО ВСТУПАТЬ В РОССИЙСКИЙ РЕГИСТР?</div>
				<div class="about__row step4">
					<div class="about__row-column">
						<div class="about__row-column-row">
							<div class="about__row-image about__row-image--3">
								<img src="@/assets/img/flag.svg" alt="">
							</div>
							<div class="about__row-column-wrapper">
								<div class="about__row-number">194</div>
								<div class="about__row-title">Этноса <br>проживает <br>на территории <br>России</div>
							</div>
						</div>
						<div class="about__row-text">Чем малочисленнее этническая <br>группа, тем сложнее найти донора</div>
					</div>
					<div class="about__row-column">
						<ul class="about__row-list large">
							<li>меньше времени тратится <br>на поиск и активацию <br>донора</li>
							<li>обеспечивается многообразие российских генотипов</li>
							<li>активация российского донора обходится дешевле</li>
						</ul>
					</div>
				</div>
				<router-link to="/donation" class="about__link" @click.native="sendEvent('activacia_sdat')">СЛЕДУЮЩИЙ ШАГ: Сдать стволовые клетки</router-link>
			</div>
		</div>
		<div class="about__interactive about__interactive--green">
			<div class="about__image about__image--medium">
				<div
					class="cloudimage-360"
					data-index-zero-base="2"
					data-folder="/KOLBI/"
                    data-image-list-x='[
                        "Probirk_00.png",
                        "Probirk_01.png",
                        "Probirk_02.png",
                        "Probirk_03.png",
                        "Probirk_04.png",
                        "Probirk_05.png",
                        "Probirk_06.png",
                        "Probirk_07.png",
                        "Probirk_08.png",
                        "Probirk_09.png",
                        "Probirk_10.png",
                        "Probirk_11.png",
                        "Probirk_12.png",
                        "Probirk_13.png",
                        "Probirk_14.png",
                        "Probirk_15.png",
                        "Probirk_16.png",
                        "Probirk_17.png",
                        "Probirk_18.png",
                        "Probirk_19.png",
                        "Probirk_20.png",
                        "Probirk_21.png",
                        "Probirk_22.png",
                        "Probirk_23.png",
                        "Probirk_24.png",
                        "Probirk_25.png",
                        "Probirk_26.png",
                        "Probirk_27.png",
                        "Probirk_28.png",
                        "Probirk_29.png",
                        "Probirk_29.png",
                        "Probirk_28.png",
                        "Probirk_27.png",
                        "Probirk_26.png",
                        "Probirk_25.png",
                        "Probirk_24.png",
                        "Probirk_23.png",
                        "Probirk_22.png",
                        "Probirk_21.png",
                        "Probirk_20.png",
                        "Probirk_19.png",
                        "Probirk_18.png",
                        "Probirk_17.png",
                        "Probirk_16.png",
                        "Probirk_15.png",
                        "Probirk_14.png",
                        "Probirk_13.png",
                        "Probirk_12.png",
                        "Probirk_11.png",
                        "Probirk_10.png",
                        "Probirk_09.png",
                        "Probirk_08.png",
                        "Probirk_07.png",
                        "Probirk_06.png",
                        "Probirk_05.png",
                        "Probirk_04.png",
                        "Probirk_03.png",
                        "Probirk_02.png",
                        "Probirk_01.png"
                    ]'
					v-show="ready"
					data-autoplay
				></div>
			</div>
			<div class="about__label">
				<span class="about__label-text">ЭТО БЕЗВОЗМЕЗДНО</span>
			</div>
		</div>
		<a href="javascript:void(0)" class="about__myth" @click="modal = true" :class="{scrolled: scrolled > 100, 'about__myth--green': scrolled < 100, 'about__myth--pink': scrolled > 99}">
			<span class="about__myth-text"><span><strong>миф</strong>  <br>длительное восстановление <br>после процедуры донации <br><strong class="underline">УЗНАТЬ БОЛЬШЕ</strong></span></span>
		</a>
		<Modal v-if="modal" @close="modal = false"></Modal>
	</div>
</template>

<script>
  import Modal from '@/components/Popup_4.vue'
  export default {
	name: "Step_4",
	data() {
		return {
			modal: false,
			ready: false,
			scrolled: 0,
		}
	},
	components: {
		Modal,
	},
	mounted() {
		var element = document.getElementById('box');
		element.addEventListener('scroll', () => {
			this.scrolled = element.scrollTop;
		})
		window.CI360.init();
		setTimeout(() => {
			this.ready = true;
		}, 500)
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/about.sass'
</style>