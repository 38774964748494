//import axios from 'axios'

export default {
  state: {

  },

  getters: {

  },

  actions: {

  },

  mutations: {

  },
};
