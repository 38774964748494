<template>
	<div class="popup">
		<div class="popup__bg"></div>
		<div class="popup__window popup__window--pink">
			<a href="javascript:void(0)" @click="$emit('close')" class="popup__close"></a>
			<div class="popup__title desk"><strong>Существует миф, что</strong> <br>Донорство костного мозга — это <br>больно и опасно для здоровья</div>
			<div class="popup__title mob"><strong>Существует миф, что</strong> <br>Донорство костного <br>мозга — это больно <br>и опасно для здоровья</div>
			<div class="popup__text">Донорство безопасно и практически безболезненно, а побочные эффекты сопоставимы <br>с походом к стоматологу. Люди, которые делают донацию через венозную кровь, могут <br>жаловаться на головную боль, слабость, тошноту или ломоту в теле, которые возникают <br>на фоне уколов при подготовке к процедуре. Но врачи знают, как бороться с этими <br>ощущениями, чтобы донор не испытывал дискомфорта. Если же донация происходит <br>через прокол тазовой кости, то возможные побочные эффекты после выхода <br>из наркоза — слабость, тошнота, болевой синдром. Все эти симптомы проходят <br>в течение суток после процедуры. Серьезные осложнения исключены, потому <br>что людей, имеющих предрасположенность к ним, отводят от донации еще на этапе <br>обследования перед ней. По данным одного из исследований, из 12 000 процедур <br>донорства за 2014 год только в 9 случаях сообщили о нежелательных явлениях.</div>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Popup_5",
	mounted() {
		this.sendEvent('donacia_mif');
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/popup.sass'
</style>