<template>
	<div class="about">
		<div class="about__info">
			<div class="about__info-top about__info-top--green">
				<router-link to="/search" class="back-btn">
					<span class="arrow"></span>
					Вернуться
				</router-link>
				<div class="about__title">
					Активация донора
				</div>
				<div class="about__text">
					<p><strong>Идеальная пара найдена, но даже <br>это не означает, что донация состоится. Хотя цель процедуры — спасти жизнь больного, у врачей в приоритете здоровье донора. Это значит, что после подтверждения совпадения вас пригласят на комплексное обследование в центр заготовки. Возможно, вам придется совершить путешествие через всю Россию.</strong></p>
					<p>Поездку, проживание и все медицинские процедуры оплатит благотворительный фонд или регистр. За сдачу костного мозга вы не получите никаких денег, потому <br>что безвозмездность — это один из основных принципов донорства. Пациенту проведут пересадку стволовых клеток бесплатно — процедура финансируется государством <br>по квоте высокотехнологичной медицинской помощи.</p>
					<p>Но вот поиск и активацию донора ваша половинка оплачивает сама или с помощью благотворительного фонда. <br>И это значительная сумма: активация донора из российского регистра в среднем стоит 310–450 тысяч рублей, а из международного регистра — от 23 тысяч евро. И это еще одна причина, почему важно вступать в регистр <br>в России.</p>
					<p>Когда вы приедете в центр, вам проведут обследование. Оно включает анализы мочи и крови, электрокардиограмму, рентгенографию легких. Кроме того, <br>с донором обязательно общается врач, который объясняет все нюансы предстоящей процедуры и отвечает на любые вопросы.</p>
				</div>
				<div class="about__interactive about__interactive--green">
					<div class="about__image about__image--medium">
						<div
							class="cloudimage-360"
							data-index-zero-base="2"
							data-folder="/KOLBI/"
							data-image-list-x='[
								"Probirk_00.png",
								"Probirk_01.png",
								"Probirk_02.png",
								"Probirk_03.png",
								"Probirk_04.png",
								"Probirk_05.png",
								"Probirk_06.png",
								"Probirk_07.png",
								"Probirk_08.png",
								"Probirk_09.png",
								"Probirk_10.png",
								"Probirk_11.png",
								"Probirk_12.png",
								"Probirk_13.png",
								"Probirk_14.png",
								"Probirk_15.png",
								"Probirk_16.png",
								"Probirk_17.png",
								"Probirk_18.png",
								"Probirk_19.png",
								"Probirk_20.png",
								"Probirk_21.png",
								"Probirk_22.png",
								"Probirk_23.png",
								"Probirk_24.png",
								"Probirk_25.png",
								"Probirk_26.png",
								"Probirk_27.png",
								"Probirk_28.png",
								"Probirk_29.png",
								"Probirk_29.png",
								"Probirk_28.png",
								"Probirk_27.png",
								"Probirk_26.png",
								"Probirk_25.png",
								"Probirk_24.png",
								"Probirk_23.png",
								"Probirk_22.png",
								"Probirk_21.png",
								"Probirk_20.png",
								"Probirk_19.png",
								"Probirk_18.png",
								"Probirk_17.png",
								"Probirk_16.png",
								"Probirk_15.png",
								"Probirk_14.png",
								"Probirk_13.png",
								"Probirk_12.png",
								"Probirk_11.png",
								"Probirk_10.png",
								"Probirk_09.png",
								"Probirk_08.png",
								"Probirk_07.png",
								"Probirk_06.png",
								"Probirk_05.png",
								"Probirk_04.png",
								"Probirk_03.png",
								"Probirk_02.png",
								"Probirk_01.png"
							]'
							data-autoplay
						></div>
					</div>
					<div class="about__label">
						<span class="about__label-text">ЭТО БЕЗВОЗМЕЗДНО</span>
					</div>
				</div>
			</div>
			<div class="about__content">
				<div class="about__text">
					<p>Обычно обследование занимает один-два дня, после чего можно возвращаться домой и ждать результатов. Они становятся известны в течение недели. Иногда, <br>если врачей в них что‑то настораживает, может потребоваться повторный приезд <br>в центр заготовки. Но чаще всего во второй раз вы поедете уже непосредственно <br>на процедуру донации.</p>
					<p>И вот тут для вашей идеальной половинки наступает решающий момент. За 5–10 дней до трансплантации пациенту проводят высокодозную химиотерапию, которая полностью уничтожает его кроветворную и иммунную системы. В этот момент <br>уже нельзя отказаться от процедуры донорства, даже если произойдет какая‑то чрезвычайная ситуация. Просто потому <br>что иначе человек погибнет.</p>
				</div>
					<a href="javascript:void(0)" class="about__myth about__myth--green rellax-h" :class="{'go': scrolled > 70}" @click="modal = true" data-rellax-vertical-speed="0" data-rellax-horizontal-speed="-0.2" data-rellax-vertical-scroll-axis="x"  data-rellax-percentage="0.48">
					<span class="about__myth-text"><span><strong>миф</strong>  <br>длительное восстановление <br>после процедуры донации <br><strong class="underline">УЗНАТЬ БОЛЬШЕ</strong></span></span>
				</a>
				<div class="about__bottom">
					<div class="about__row-title">ПОЧЕМУ ВАЖНО ВСТУПАТЬ <br>В РОССИЙСКИЙ РЕГИСТР?</div>
					<div class="about__row step4">
						<div class="about__row-column">
							<div class="about__row-column-row">
								<div class="about__row-image about__row-image--3">
									<img src="@/assets/img/flag.svg" alt="">
								</div>
								<div class="about__row-column-wrapper">
									<div class="about__row-number">194</div>
									<div class="about__row-title">Этноса <br>проживает <br>на территории <br>России</div>
								</div>
							</div>
							<div class="about__row-text">Чем малочисленнее этническая <br>группа, тем сложнее найти донора</div>
						</div>
						<div class="about__row-column">
							<ul class="about__row-list large">
								<li>меньше времени тратится <br>на поиск и активацию <br>донора</li>
								<li>обеспечивается многообразие российских генотипов</li>
								<li>активация российского донора обходится дешевле</li>
							</ul>
						</div>
					</div>
					<router-link to="/donation" class="about__link" @click.native="sendEvent('activacia_sdat')"><span>СЛЕДУЮЩИЙ ШАГ: <br>Сдать стволовые клетки</span></router-link>
				</div>
			</div>
		</div>
		<Modal v-if="modal" @close="modal = false"></Modal>
	</div>
</template>

<script>
  import Modal from '@/components/Popup_4.vue'
  export default {
	name: "Step_mob_4",
	data() {
		return {
			modal: false,
			scrolled: 0,
		}
	},
	components: {
		Modal,
	},
	mounted() {
		window.CI360.init();
		document.addEventListener('scroll', () => {
			var h = document.documentElement, 
				b = document.body,
				st = 'scrollTop',
				sh = 'scrollHeight';

			this.scrolled = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
		})
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/about.sass'
</style>