<template>
	<div class="hero hero--finish">
		<div class="hero__animated hero__animated--desktop">
			<div
				class="cloudimage-360"
				data-folder="/BG_1/"
				data-index-zero-base="2"
				data-filename-x="desc_{index}.jpg"
				data-amount-x="59"
				data-autoplay
			></div>
		</div>
		<div class="hero__animated hero__animated--mobile">
			<div
				class="cloudimage-360"
				data-folder="/BG_1_MOB/"
				data-index-zero-base="2"
				data-filename-x="mob_{index}.jpg"
				data-amount-x="59"
				data-autoplay
			></div>
		</div>
		<div class="container">
			<div class="hero__content">
				<div class="hero__head">
					<div class="hero__title hero__title--3">Идеальные совпадения <br>бывают — помогите <br>одному из них случиться</div>
				</div>
				<div class="hero__text">У каждого человека найдутся события, которыми он гордится: преодоленные трудности, удачная карьера, волонтерство, счастливый брак и многое другое. Этот список можно пополнить по‑настоящему героическим поступком. Вступление в регистр доноров костного мозга — первый шаг на пути к тому, чтобы спасти чью‑то жизнь. Причем вы поможете не одному человеку, а всей его семье, которая боится его потерять и очень надеется на встречу с донором.</div>
				<div class="hero__button">
					<a href="https://km.donorstvo.org/" target="_blank" class="hero__button-link"> Больше информации ищите <br class="mob-only">на портале <br>Минздрава России <br class="mob-only">о донорстве костного мозга</a>
				</div>
				<div class="hero__note">Социальная реклама. Рекламодатель ФГБУ «НМИЦ гематологии» <br>Минздрава России <a href="https://blood.ru/" target="_blank">blood.ru</a></div>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Finish",
	mounted() {
		window.CI360.init();
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/hero.sass'
</style>
