<template>
  <div id="app" class="wrapper">
    <Preloader v-if="isLoading"></Preloader>
    <router-view />
  </div>
</template>

<script>
  import Preloader from '@/components/Preloader.vue'
  export default {
    name: 'App',
    data() {
      return {
        isLoading: true
      }
    },
    methods: {
      preloadImages(images) {
        let loadedCount = 0;

        images.forEach(src => {
          const img = new Image();
          img.onload = () => {
            loadedCount++;
            if (loadedCount === images.length) {
              this.isLoading = false;
            }
          };
          img.onerror = () => {
            console.warn(`Ошибка при загрузке изображения: ${src}`);
            loadedCount++;
            if (loadedCount === images.length) {
              this.isLoading = false;
            }
          };
          img.src = src;
        });
      }
    },
    mounted() {
      const imagesToLoad = [
        '/BG_1/desc_00.jpg',
        '/BG_1/desc_01.jpg',
        '/BG_1/desc_02.jpg',
        '/BG_1/desc_03.jpg',
        '/BG_1/desc_04.jpg',
        '/BG_1/desc_05.jpg',
        '/BG_1/desc_06.jpg',
        '/BG_1/desc_07.jpg',
        '/BG_1/desc_08.jpg',
        '/BG_1/desc_09.jpg',
        '/BG_1/desc_10.jpg',
        '/BG_1/desc_11.jpg',
        '/BG_1/desc_12.jpg',
        '/BG_1/desc_13.jpg',
        '/BG_1/desc_14.jpg',
        '/BG_1/desc_15.jpg',
        '/BG_1/desc_16.jpg',
        '/BG_1/desc_17.jpg',
        '/BG_1/desc_18.jpg',
        '/BG_1/desc_19.jpg',
        '/BG_1/desc_20.jpg',
        '/BG_1/desc_21.jpg',
        '/BG_1/desc_22.jpg',
        '/BG_1/desc_23.jpg',
        '/BG_1/desc_24.jpg',
        '/BG_1/desc_25.jpg',
        '/BG_1/desc_26.jpg',
        '/BG_1/desc_27.jpg',
        '/BG_1/desc_28.jpg',
        '/BG_1/desc_29.jpg',
        '/BG_1/desc_30.jpg',
        '/BG_1/desc_31.jpg',
        '/BG_1/desc_32.jpg',
        '/BG_1/desc_33.jpg',
        '/BG_1/desc_34.jpg',
        '/BG_1/desc_35.jpg',
        '/BG_1/desc_36.jpg',
        '/BG_1/desc_37.jpg',
        '/BG_1/desc_38.jpg',
        '/BG_1/desc_39.jpg',
        '/BG_1/desc_40.jpg',
        '/BG_1/desc_41.jpg',
        '/BG_1/desc_42.jpg',
        '/BG_1/desc_43.jpg',
        '/BG_1/desc_44.jpg',
        '/BG_1/desc_45.jpg',
        '/BG_1/desc_46.jpg',
        '/BG_1/desc_47.jpg',
        '/BG_1/desc_48.jpg',
        '/BG_1/desc_49.jpg',
        '/BG_1/desc_50.jpg',
        '/BG_1/desc_51.jpg',
        '/BG_1/desc_52.jpg',
        '/BG_1/desc_53.jpg',
        '/BG_1/desc_54.jpg',
        '/BG_1/desc_55.jpg',
        '/BG_1/desc_56.jpg',
        '/BG_1/desc_57.jpg',
        '/BG_1/desc_58.jpg',
        '/BG_1/desc_59.jpg',
        '/BG_1_MOB/mob_00.jpg',
        '/BG_1_MOB/mob_01.jpg',
        '/BG_1_MOB/mob_02.jpg',
        '/BG_1_MOB/mob_03.jpg',
        '/BG_1_MOB/mob_04.jpg',
        '/BG_1_MOB/mob_05.jpg',
        '/BG_1_MOB/mob_06.jpg',
        '/BG_1_MOB/mob_07.jpg',
        '/BG_1_MOB/mob_08.jpg',
        '/BG_1_MOB/mob_09.jpg',
        '/BG_1_MOB/mob_10.jpg',
        '/BG_1_MOB/mob_11.jpg',
        '/BG_1_MOB/mob_12.jpg',
        '/BG_1_MOB/mob_13.jpg',
        '/BG_1_MOB/mob_14.jpg',
        '/BG_1_MOB/mob_15.jpg',
        '/BG_1_MOB/mob_16.jpg',
        '/BG_1_MOB/mob_17.jpg',
        '/BG_1_MOB/mob_18.jpg',
        '/BG_1_MOB/mob_19.jpg',
        '/BG_1_MOB/mob_20.jpg',
        '/BG_1_MOB/mob_21.jpg',
        '/BG_1_MOB/mob_22.jpg',
        '/BG_1_MOB/mob_23.jpg',
        '/BG_1_MOB/mob_24.jpg',
        '/BG_1_MOB/mob_25.jpg',
        '/BG_1_MOB/mob_26.jpg',
        '/BG_1_MOB/mob_27.jpg',
        '/BG_1_MOB/mob_28.jpg',
        '/BG_1_MOB/mob_29.jpg',
        '/BG_1_MOB/mob_30.jpg',
        '/BG_1_MOB/mob_31.jpg',
        '/BG_1_MOB/mob_32.jpg',
        '/BG_1_MOB/mob_33.jpg',
        '/BG_1_MOB/mob_34.jpg',
        '/BG_1_MOB/mob_35.jpg',
        '/BG_1_MOB/mob_36.jpg',
        '/BG_1_MOB/mob_37.jpg',
        '/BG_1_MOB/mob_38.jpg',
        '/BG_1_MOB/mob_39.jpg',
        '/BG_1_MOB/mob_40.jpg',
        '/BG_1_MOB/mob_41.jpg',
        '/BG_1_MOB/mob_42.jpg',
        '/BG_1_MOB/mob_43.jpg',
        '/BG_1_MOB/mob_44.jpg',
        '/BG_1_MOB/mob_45.jpg',
        '/BG_1_MOB/mob_46.jpg',
        '/BG_1_MOB/mob_47.jpg',
        '/BG_1_MOB/mob_48.jpg',
        '/BG_1_MOB/mob_49.jpg',
        '/BG_1_MOB/mob_50.jpg',
        '/BG_1_MOB/mob_51.jpg',
        '/BG_1_MOB/mob_52.jpg',
        '/BG_1_MOB/mob_53.jpg',
        '/BG_1_MOB/mob_54.jpg',
        '/BG_1_MOB/mob_55.jpg',
        '/BG_1_MOB/mob_56.jpg',
        '/BG_1_MOB/mob_57.jpg',
        '/BG_1_MOB/mob_58.jpg',
        '/BG_1_MOB/mob_59.jpg',
        '/BG_2/desc_light_00.jpg',
        '/BG_2/desc_light_01.jpg',
        '/BG_2/desc_light_02.jpg',
        '/BG_2/desc_light_03.jpg',
        '/BG_2/desc_light_04.jpg',
        '/BG_2/desc_light_05.jpg',
        '/BG_2/desc_light_06.jpg',
        '/BG_2/desc_light_07.jpg',
        '/BG_2/desc_light_08.jpg',
        '/BG_2/desc_light_09.jpg',
        '/BG_2/desc_light_10.jpg',
        '/BG_2/desc_light_11.jpg',
        '/BG_2/desc_light_12.jpg',
        '/BG_2/desc_light_13.jpg',
        '/BG_2/desc_light_14.jpg',
        '/BG_2/desc_light_15.jpg',
        '/BG_2/desc_light_16.jpg',
        '/BG_2/desc_light_17.jpg',
        '/BG_2/desc_light_18.jpg',
        '/BG_2/desc_light_19.jpg',
        '/BG_2/desc_light_20.jpg',
        '/BG_2/desc_light_21.jpg',
        '/BG_2/desc_light_22.jpg',
        '/BG_2/desc_light_23.jpg',
        '/BG_2/desc_light_24.jpg',
        '/BG_2/desc_light_25.jpg',
        '/BG_2/desc_light_26.jpg',
        '/BG_2/desc_light_27.jpg',
        '/BG_2/desc_light_28.jpg',
        '/BG_2/desc_light_29.jpg',
        '/BG_2/desc_light_30.jpg',
        '/BG_2/desc_light_31.jpg',
        '/BG_2/desc_light_32.jpg',
        '/BG_2/desc_light_33.jpg',
        '/BG_2/desc_light_34.jpg',
        '/BG_2/desc_light_35.jpg',
        '/BG_2/desc_light_36.jpg',
        '/BG_2/desc_light_37.jpg',
        '/BG_2/desc_light_38.jpg',
        '/BG_2/desc_light_39.jpg',
        '/BG_2_MOB/light_mob_00.jpg',
        '/BG_2_MOB/light_mob_01.jpg',
        '/BG_2_MOB/light_mob_02.jpg',
        '/BG_2_MOB/light_mob_03.jpg',
        '/BG_2_MOB/light_mob_04.jpg',
        '/BG_2_MOB/light_mob_05.jpg',
        '/BG_2_MOB/light_mob_06.jpg',
        '/BG_2_MOB/light_mob_07.jpg',
        '/BG_2_MOB/light_mob_08.jpg',
        '/BG_2_MOB/light_mob_09.jpg',
        '/BG_2_MOB/light_mob_10.jpg',
        '/BG_2_MOB/light_mob_11.jpg',
        '/BG_2_MOB/light_mob_12.jpg',
        '/BG_2_MOB/light_mob_13.jpg',
        '/BG_2_MOB/light_mob_14.jpg',
        '/BG_2_MOB/light_mob_15.jpg',
        '/BG_2_MOB/light_mob_16.jpg',
        '/BG_2_MOB/light_mob_17.jpg',
        '/BG_2_MOB/light_mob_18.jpg',
        '/BG_2_MOB/light_mob_19.jpg',
        '/BG_2_MOB/light_mob_20.jpg',
        '/BG_2_MOB/light_mob_21.jpg',
        '/BG_2_MOB/light_mob_22.jpg',
        '/BG_2_MOB/light_mob_23.jpg',
        '/BG_2_MOB/light_mob_24.jpg',
        '/BG_2_MOB/light_mob_25.jpg',
        '/BG_2_MOB/light_mob_26.jpg',
        '/BG_2_MOB/light_mob_27.jpg',
        '/BG_2_MOB/light_mob_28.jpg',
        '/BG_2_MOB/light_mob_29.jpg',
        '/BG_2_MOB/light_mob_30.jpg',
        '/BG_2_MOB/light_mob_31.jpg',
        '/BG_2_MOB/light_mob_32.jpg',
        '/BG_2_MOB/light_mob_33.jpg',
        '/BG_2_MOB/light_mob_34.jpg',
        '/BG_2_MOB/light_mob_35.jpg',
        '/BG_2_MOB/light_mob_36.jpg',
        '/BG_2_MOB/light_mob_37.jpg',
        '/BG_2_MOB/light_mob_38.jpg',
        '/BG_2_MOB/light_mob_39.jpg',
        '/BLOOD/bld_00.png',
        '/BLOOD/bld_01.png',
        '/BLOOD/bld_02.png',
        '/BLOOD/bld_03.png',
        '/BLOOD/bld_04.png',
        '/BLOOD/bld_05.png',
        '/BLOOD/bld_06.png',
        '/BLOOD/bld_07.png',
        '/BLOOD/bld_08.png',
        '/BLOOD/bld_09.png',
        '/BLOOD/bld_10.png',
        '/BLOOD/bld_11.png',
        '/BLOOD/bld_12.png',
        '/BLOOD/bld_13.png',
        '/BLOOD/bld_14.png',
        '/BLOOD/bld_15.png',
        '/BLOOD/bld_16.png',
        '/BLOOD/bld_17.png',
        '/BLOOD/bld_18.png',
        '/BLOOD/bld_19.png',
        '/BLOOD/bld_20.png',
        '/BLOOD/bld_21.png',
        '/BLOOD/bld_22.png',
        '/BLOOD/bld_23.png',
        '/BLOOD/bld_24.png',
        '/BLOOD/bld_25.png',
        '/BLOOD/bld_26.png',
        '/BLOOD/bld_27.png',
        '/BLOOD/bld_28.png',
        '/BLOOD/bld_29.png',
        '/DNA23/image-1.png',
        '/DNA23/image-2.png',
        '/DNA23/image-3.png',
        '/DNA23/image-4.png',
        '/DNA23/image-5.png',
        '/DNA23/image-6.png',
        '/DNA23/image-7.png',
        '/DNA23/image-8.png',
        '/DNA23/image-9.png',
        '/DNA23/image-10.png',
        '/DNA23/image-11.png',
        '/DNA23/image-12.png',
        '/DNA23/image-13.png',
        '/DNA23/image-14.png',
        '/DNA23/image-15.png',
        '/DNA23/image-16.png',
        '/DNA23/image-17.png',
        '/DNA23/image-18.png',
        '/DNA23/image-19.png',
        '/DNA23/image-20.png',
        '/DNA23/image-21.png',
        '/DNA23/image-22.png',
        '/DNA23/image-23.png',
        '/DNA23/image-24.png',
        '/DNA23/image-25.png',
        '/DNA23/image-26.png',
        '/DNA23/image-27.png',
        '/DNA23/image-28.png',
        '/DNA23/image-29.png',
        '/KOLBI/Probirk_00.png',
        '/KOLBI/Probirk_01.png',
        '/KOLBI/Probirk_02.png',
        '/KOLBI/Probirk_03.png',
        '/KOLBI/Probirk_04.png',
        '/KOLBI/Probirk_05.png',
        '/KOLBI/Probirk_06.png',
        '/KOLBI/Probirk_07.png',
        '/KOLBI/Probirk_08.png',
        '/KOLBI/Probirk_09.png',
        '/KOLBI/Probirk_10.png',
        '/KOLBI/Probirk_11.png',
        '/KOLBI/Probirk_12.png',
        '/KOLBI/Probirk_13.png',
        '/KOLBI/Probirk_14.png',
        '/KOLBI/Probirk_15.png',
        '/KOLBI/Probirk_16.png',
        '/KOLBI/Probirk_17.png',
        '/KOLBI/Probirk_18.png',
        '/KOLBI/Probirk_19.png',
        '/KOLBI/Probirk_20.png',
        '/KOLBI/Probirk_21.png',
        '/KOLBI/Probirk_22.png',
        '/KOLBI/Probirk_23.png',
        '/KOLBI/Probirk_24.png',
        '/KOLBI/Probirk_25.png',
        '/KOLBI/Probirk_26.png',
        '/KOLBI/Probirk_27.png',
        '/KOLBI/Probirk_28.png',
        '/KOLBI/Probirk_29.png',
        '/MICRO/Micro_00.png',
        '/MICRO/Micro_01.png',
        '/MICRO/Micro_02.png',
        '/MICRO/Micro_03.png',
        '/MICRO/Micro_04.png',
        '/MICRO/Micro_05.png',
        '/MICRO/Micro_06.png',
        '/MICRO/Micro_07.png',
        '/MICRO/Micro_08.png',
        '/MICRO/Micro_09.png',
        '/MICRO/Micro_10.png',
        '/MICRO/Micro_11.png',
        '/MICRO/Micro_12.png',
        '/MICRO/Micro_13.png',
        '/MICRO/Micro_14.png',
        '/MICRO/Micro_15.png',
        '/MICRO/Micro_16.png',
        '/MICRO/Micro_17.png',
        '/MICRO/Micro_18.png',
        '/MICRO/Micro_19.png',
        '/MICRO/Micro_20.png',
        '/MICRO/Micro_21.png',
        '/MICRO/Micro_22.png',
        '/MICRO/Micro_23.png',
        '/MICRO/Micro_24.png',
        '/MICRO/Micro_25.png',
        '/MICRO/Micro_26.png',
        '/MICRO/Micro_27.png',
        '/MICRO/Micro_28.png',
        '/MICRO/Micro_29.png',
        '/TIME/SandTime_00.png',
        '/TIME/SandTime_01.png',
        '/TIME/SandTime_02.png',
        '/TIME/SandTime_03.png',
        '/TIME/SandTime_04.png',
        '/TIME/SandTime_05.png',
        '/TIME/SandTime_06.png',
        '/TIME/SandTime_07.png',
        '/TIME/SandTime_08.png',
        '/TIME/SandTime_09.png',
        '/TIME/SandTime_10.png',
        '/TIME/SandTime_11.png',
        '/TIME/SandTime_12.png',
        '/TIME/SandTime_13.png',
        '/TIME/SandTime_14.png',
        '/TIME/SandTime_15.png',
        '/TIME/SandTime_16.png',
        '/TIME/SandTime_17.png',
        '/TIME/SandTime_18.png',
        '/TIME/SandTime_19.png',
        '/TIME/SandTime_20.png',
        '/TIME/SandTime_21.png',
        '/TIME/SandTime_22.png',
        '/TIME/SandTime_23.png',
        '/TIME/SandTime_24.png',
        '/TIME/SandTime_25.png',
        '/TIME/SandTime_26.png',
        '/TIME/SandTime_27.png',
        '/TIME/SandTime_28.png',
        '/TIME/SandTime_29.png',
      ];

      this.preloadImages(imagesToLoad);
    },
    components: {
      Preloader,
    },
  }
</script>

<style lang="scss">
  @import '@/assets/sass/functions.sass';
  article,aside,details,figcaption,figure,footer,header,hgroup,hr,menu,nav,section{display:block}a,hr{padding:0}abbr,address,article,aside,audio,b,blockquote,body,canvas,caption,cite,code,dd,del,details,dfn,div,dl,dt,em,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,p,pre,q,samp,section,small,span,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,ul,var,video{margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:0 0}ins,mark{background-color:#ff9;color:#000}body{line-height:1}nav ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}a{margin:0;font-size:100%;vertical-align:baseline;background:0 0}ins{text-decoration:none}mark{font-style:italic;font-weight:700}del{text-decoration:line-through}abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}table{border-collapse:collapse;border-spacing:0}hr{height:1px;border:0;border-top:1px solid #ccc;margin:1em 0}input,select{vertical-align:middle}

  body {
    background-color: #fff;
    overflow-x: hidden;
    font-family: 'Montserrat';
  }

  .container {
    width: vw(1356px);
    margin: auto;
    @media screen and (max-width: 1000px) {
      width: auto;
      padding-left: vwm(20px);
      padding-right: vwm(20px);
    }
  }


  .wrapper {
    max-width: 100%;
    overflow: hidden;
    position: relative;
  }

  .back-btn {
    display: inline-flex;
    cursor: pointer;
    font-size: vw(12px);
    font-family: 'Ysabeau';
    text-transform: uppercase;
    color: $dark-grey;
    margin-bottom: vw(55px);
    margin-left: -1px;
    text-decoration: none;
    @media screen and (max-width: 1000px) {
      font-size: vwm(12px);
      margin-bottom: vwm(31px);
      margin-left: vwm(2px);
    }
    &:hover {
      span.arrow {
        background-image: url(./assets/img/long-arrow-hover.svg);
      }
    }
    span {
      &.arrow {
        margin-top: -1px;
        background-image: url(./assets/img/long-arrow.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: vw(51px);
        height: vw(16px);
        margin-right: vw(8px);
        transition: all 300ms;
        @media screen and (max-width: 1000px) {
          width: vwm(51px);
          height: vwm(16px);
          margin-right: vwm(8px);
        }
      }
    }
  }
@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-ExtraLight.eot');
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
        url('./assets/fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Montserrat-ExtraLight.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-ExtraLight.woff') format('woff'),
        url('./assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Regular.eot');
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('./assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Montserrat-Regular.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-Regular.woff') format('woff'),
        url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Bold.eot');
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('./assets/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Montserrat-Bold.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-Bold.woff') format('woff'),
        url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Light.eot');
    src: local('Montserrat Light'), local('Montserrat-Light'),
        url('./assets/fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Montserrat-Light.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-Light.woff') format('woff'),
        url('./assets/fonts/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Black.eot');
    src: local('Montserrat Black'), local('Montserrat-Black'),
        url('./assets/fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Montserrat-Black.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-Black.woff') format('woff'),
        url('./assets/fonts/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Medium.eot');
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
        url('./assets/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Montserrat-Medium.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-Medium.woff') format('woff'),
        url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-ExtraBold.eot');
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
        url('./assets/fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Montserrat-ExtraBold.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-ExtraBold.woff') format('woff'),
        url('./assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-SemiBold.eot');
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url('./assets/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-SemiBold.woff') format('woff'),
        url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Thin.eot');
    src: local('Montserrat Thin'), local('Montserrat-Thin'),
        url('./assets/fonts/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Montserrat-Thin.woff2') format('woff2'),
        url('./assets/fonts/Montserrat-Thin.woff') format('woff'),
        url('./assets/fonts/Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
.cloudimage-360-view-360-icon {
  display: none
}
.cloudimage-360-loader {
  display: none !important;
  overflow: 0;
  visibility: hidden;
}
.router-link-exact-active {
  color: #e8198b !important;
}
.rellax-h {
  transition: transform 300ms;
}
</style>