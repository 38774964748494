<template>
	<div class="about">
		<div class="about__info">
			<div class="about__info-top about__info-top--violet">
				<router-link to="/donation" class="back-btn">
					<span class="arrow"></span>
					Вернуться
				</router-link>
				<div class="about__title">
					Встреча с идеальной парой
				</div>
				<div class="about__text">
					<p><strong>Вы сдали костный мозг и вернулись домой. Вашему генетическому близнецу выполнили трансплантацию. Но кто он, помогла ли процедура? Об этом вы узнаете постепенно.</strong></p>
					<p>О безвозмездности и добровольности донорства вы уже слышали. Еще один важный принцип — анонимность. <br>Она защищает обе стороны от неблаговидных поступков, например от попыток надавить на жалость со стороны родственников пациента или заработать <br>на чужом горе со стороны донора. Кроме того, она оберегает и вас, и вашу половинку от взаимных поисков в соцсетях, чтобы знание пола, возраста, национальности, политических убеждений, материального положения или чего‑то еще не стало поводом для отказа от донации.</p>
					<p>Однако в случае с донорством костного мозга анонимность постепенно снимается. До процедуры ни вы, ни ваша половинка ничего не знаете друг о друге. Во время подготовки к донации вам сообщат пол <br>и примерный возраст человека, чью жизнь вы спасаете. Вы узнаете, например, <br>что это «юноша 19 лет» или «женщина <br>за 40 лет».</p>
					<p>Если у вас есть желание узнать, прижился <br>ли ваш костный мозг, то через месяц <br>после донации вы можете выяснить <br>у сотрудника регистра,</p>
				</div>
				<div class="about__interactive about__interactive--violet-2">
					<div class="hands-mob">
						<img src="@/assets/img/hand-1-m.png" class="hands-mob__1" alt="">
						<img src="@/assets/img/hand-2-m.png" class="hands-mob__2" alt="">
					</div>
					<div class="about__label">
						<span class="about__label-text">Верьте <br>в добро</span>
					</div>
				</div>
			</div>
			<div class="about__content">
				<a href="javascript:void(0)" class="about__myth about__myth--step6 about__myth--violet rellax-h" :class="{'go': scrolled > 70}" @click="modal = true"  data-rellax-vertical-speed="0" data-rellax-horizontal-speed="-0.2" data-rellax-vertical-scroll-axis="x"  data-rellax-percentage="0.48">
					<span class="about__myth-text"><span><strong>миф</strong>  <br>Быть донором можно только <br>один раз в жизни <br><strong class="underline">УЗНАТЬ БОЛЬШЕ</strong></span></span>
				</a>
				<div class="about__text">
					<p>как чувствует себя реципиент. Тогда же, <br>если у вас и у вашей половинки <br>есть взаимное согласие, можно начать переписку. Правда, все равно придется соблюдать анонимность и не указывать никакой личной информации: места жительства, возраста и даже имени.</p>
					<p>Переписка будет происходить через врачей, которые в обязательном порядке вскроют корреспонденцию и проверят, <br>нет ли там данных, которые могут позволить раскрыть вам личность друг друга. Так будет продолжаться два года. И только когда <br>они истекут, анонимность будет снята. <br>После этого — если есть обоюдное <br>желание — вы и ваша половинка можете встретиться онлайн или в реальности.</p>
					<p>Те люди, которые соглашаются на встречу, почти всегда отзываются о ней как об опыте, который позволяет пересмотреть свои ценности. Доноры начинают выше ценить каждую минуту в жизни, потому что через личную историю своего генетического близнеца узнают, что это значит — оказаться на грани. А для реципиентов это всегда возможность больше верить в добро <br>и в хороших людей.</p>
					<p>Еще недавно онкологические заболевания крови были равносильны приговору. Сейчас они лечатся. И это чудо происходит благодаря донору. Он отдает только небольшую часть своих клеток, тратит <br>на все немного времени, но зато получает возможность спасти чью‑то жизнь <br>и приобрести по‑настоящему близкого человека. Как говорят и доноры, <br>и реципиенты, они получают нового члена семьи, на которого могут теперь во всем положиться. Ведь вы и ваша половинка теперь одной крови.</p>
				</div>
				<div class="about__bottom">
					<router-link to="/miracle" class="about__link" @click.native="sendEvent('vstrecha_chudo')"><span>СЛЕДУЮЩИЙ ШАГ: <br>СОВЕРШИТЬ ЧУДО</span></router-link>
				</div>
			</div>
		</div>
		<Modal v-if="modal" @close="modal = false"></Modal>
	</div>
</template>

<script>
	import Modal from '@/components/Popup_6.vue'
	export default {
		name: "Step_mob_6",
		data() {
			return {
				modal: false,
				scrolled: 0,
			}
		},
		components: {
			Modal,
		},
		mounted() {
			window.CI360.init();
			document.addEventListener('scroll', () => {
				var h = document.documentElement, 
					b = document.body,
					st = 'scrollTop',
					sh = 'scrollHeight';

				this.scrolled = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
			})
		}
	}
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/about.sass'
	.hands-mob
		&__1
			width: vwm(265px)
			height: auto
			position: absolute
			left: vwm(-153px)
			top: vwm(120px)
			transform: rotate(32.838deg)
			animation: hand2m
			animation-duration: 3s
			animation-timing-function: linear
			animation-iteration-count: infinite
		&__2
			width: vwm(202px)
			height: auto
			position: absolute
			right: vw(-443px)
			top: vwm(-50px)
			transform: rotate(32.503deg)
			animation: hand1m
			animation-duration: 3s
			animation-timing-function: linear
			animation-iteration-count: infinite
	@keyframes hand1m
		0%
			top: vwm(-50px)
			transform: rotate(32.503deg)
			right: vw(-443px)
		50%
			top: vwm(0px)
			transform: rotate(38.503deg)
			right: vw(-300px)
		70%
			top: vwm(0px)
			transform: rotate(38.503deg)
			right: vw(-300px)
		100%
			top: vwm(-50px)
			transform: rotate(32.503deg)
			right: vw(-443px)
	@keyframes hand2m
		0%
			top: vwm(120px)
			transform: rotate(32.503deg)
			left: vwm(-153px)
		50%
			top: vwm(120px)
			transform: rotate(38.503deg)
			left: vwm(-123px)
		70%
			top: vwm(120px)
			transform: rotate(38.503deg)
			left: vwm(-123px)
		100%
			top: vwm(120px)
			transform: rotate(32.503deg)
			left: vwm(-153px)
</style>