<template>
  <div class="page">
    <Header />
    <Start />
  </div>
</template>

<script>
  import Header from '@/components/Header'
  import Start from '@/components/Start'
  export default {
    name: "Main",
    components: {
      Header,
      Start,
    }
  }
</script>