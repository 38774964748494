<template>
	<div class="popup">
		<div class="popup__bg"></div>
		<div class="popup__window popup__window--green">
			<a href="javascript:void(0)" @click="$emit('close')" class="popup__close"></a>
			<div class="popup__title desk"><strong>Существует миф, что</strong> <br>Донором костного мозга может <br>стать только близкий родственник</div>
			<div class="popup__title mob"><strong>Существует миф, что</strong> <br>Донором костного мозга <br>может стать только <br>близкий родственник</div>
			<div class="popup__text">Если человеку требуется пересадка костного мозга, то первым делом донора ищут <br>в семье. У родных братьев и сестер возможна полная совместимость (ее вероятность <br>составляет 25%), а вот у родителей и детей генетическое совпадение всегда будет только <br>частичным (ведь ребенок наследует по 50% генов от матери и отца). <br>В некоторых ситуациях врачи могут использовать стволовые клетки от не полностью <br>совместимого родственного донора. Такой случай называется гаплоидентичной <br>трансплантацией, но у него довольно много рисков, связанных с иммунным конфликтом <br>между клетками донора и реципиента. И, конечно, не у всех пациентов могут найтись <br>родственные доноры. Поэтому единственная надежда большинства заболевших <br>людей — это посторонний генетический близнец.</div>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Popup_2",
	mounted() {
		this.sendEvent('tipirovanie_mif');
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/popup.sass'
</style>