<template>
	<div class="about">
		<div class="about__info" id="box">
			<router-link to="/solution" class="back-btn">
				<span class="arrow"></span>
				Вернуться
			</router-link>
			<div class="about__title">
				HLA-типирование
			</div>
			<div class="about__text">
				<p><strong>Однажды утром вы проснетесь и подумаете: «Сегодня подходящий день для того, чтобы совершить добрый поступок». Как и все большие дела, <br>он начинается с мелочи.</strong></p>
				<p>Для вступления в регистр доноров костного мозга надо обратиться <br>с паспортом в рекрутинговый центр. Там вам предложат заполнить анкету-согласие (так вы подтверждаете, что вы вступаете в регистр добровольно), указать контактные данные и сдать кровь на HLA-типирование — именно <br>оно позволит в будущем найти вашего генетического близнеца.</p>
				<p>Дело в том, что HLA-антигены, или система тканевой совместимости, — <br>это белки, которые работают как система распознавания свой — чужой: <br>если они обнаруживают чужеродные клетки в организме, то запускается иммунный ответ, обеспечивающий их уничтожение. Проблема в том, <br>что иммунная система может посчитать клетки пересаживаемого костного мозга вражескими и атаковать их. Начнется процесс отторжения, <br>а это опасное для жизни пациента состояние.</p>
				<p>Именно поэтому для успешной трансплантации необходимо, чтобы донор <br>и пациент совпадали по HLA-системе почти на 100%. Тогда иммунная система посчитает их своими, и процесс уничтожения не включится. Синтез белков HLA-системы определяется генами, которые расположены в 6-й хромосоме. <br>У большинства людей, у вас в том числе, есть «близнец» — человек, с которым гены в этой хромосоме идентичны.</p>
				<p>HLA-типирование — это своего рода перепись генов, именно с его помощью происходит подбор пары донор — реципиент. Для типирования <br>у вас возьмут кровь из вены (одну пробирку до 10 мл), и это практически безболезненно.</p>
				<p>Специальной подготовки не требуется, поэтому прийти можно в любое время. Заполнение анкеты и сдача крови займут максимум час. И главное, оплачивать ничего не нужно.</p>
				<p>Когда проведут HLA-типирование вашей крови, информацию о генотипе занесут в базу регистра, а вам сообщат об этом. Доступ к регистру <br>есть у специалистов трансплантационных центров. Однако <br>даже им информация доступна в обезличенном виде, то есть ваши личные <br>и контактные данные будут храниться отдельно, чтобы обеспечивать вашу конфиденциальность. Дальше остается только ждать.</p>
			</div>
			<div class="about__bottom">
				<div class="about__row step2">
					<div class="about__row-column">
						<div class="about__row-title">несоответствие</div>
						<div class="about__row-image about__row-image--1">
							<img src="@/assets/img/pazzles-1.svg" alt="">
						</div>
					</div>
					<div class="about__row-column">
						<div class="about__row-title">HLA-соответствие</div>
						<div class="about__row-image about__row-image--2">
							<img src="@/assets/img/pazzles-2.svg" alt="">
						</div>
					</div>
				</div>
				<router-link to="/search" class="about__link" @click.native="sendEvent('tipirovanie_naiti')">СЛЕДУЮЩИЙ ШАГ: найти пару</router-link>
			</div>
		</div>
		<div class="about__interactive about__interactive--green">
			<div class="about__image">
				<div
					class="cloudimage-360"
					data-index-zero-base="2"
					data-folder="/MICRO/"
                    data-image-list-x='[
                        "Micro_00.png",
                        "Micro_01.png",
                        "Micro_02.png",
                        "Micro_03.png",
                        "Micro_04.png",
                        "Micro_05.png",
                        "Micro_06.png",
                        "Micro_07.png",
                        "Micro_08.png",
                        "Micro_09.png",
                        "Micro_10.png",
                        "Micro_11.png",
                        "Micro_12.png",
                        "Micro_13.png",
                        "Micro_14.png",
                        "Micro_15.png",
                        "Micro_16.png",
                        "Micro_17.png",
                        "Micro_18.png",
                        "Micro_19.png",
                        "Micro_20.png",
                        "Micro_21.png",
                        "Micro_22.png",
                        "Micro_23.png",
                        "Micro_24.png",
                        "Micro_25.png",
                        "Micro_26.png",
                        "Micro_27.png",
                        "Micro_28.png",
                        "Micro_29.png",
                        "Micro_29.png",
                        "Micro_28.png",
                        "Micro_27.png",
                        "Micro_26.png",
                        "Micro_25.png",
                        "Micro_24.png",
                        "Micro_23.png",
                        "Micro_22.png",
                        "Micro_21.png",
                        "Micro_20.png",
                        "Micro_19.png",
                        "Micro_18.png",
                        "Micro_17.png",
                        "Micro_16.png",
                        "Micro_15.png",
                        "Micro_14.png",
                        "Micro_13.png",
                        "Micro_12.png",
                        "Micro_11.png",
                        "Micro_10.png",
                        "Micro_09.png",
                        "Micro_08.png",
                        "Micro_07.png",
                        "Micro_06.png",
                        "Micro_05.png",
                        "Micro_04.png",
                        "Micro_03.png",
                        "Micro_02.png",
                        "Micro_01.png"
                    ]'
					data-autoplay
					v-show="ready"
				></div>
			</div>
			<div class="about__label">
				<span class="about__label-text">ВСЕГО ЛИШЬ АНАЛИЗ</span>
			</div>
		</div>
		<a href="javascript:void(0)" class="about__myth" @click="modal = true" :class="{scrolled: scrolled > 100, 'about__myth--green': scrolled < 100, 'about__myth--violet': scrolled > 99}">
			<span class="about__myth-text"><span><strong>миф</strong> <br>донором может стать только <br>близкий родственник <br><strong class="underline">УЗНАТЬ БОЛЬШЕ</strong></span></span>
		</a>
		<Modal v-if="modal" @close="modal = false"></Modal>
	</div>
</template>

<script>
  import Modal from '@/components/Popup_2.vue'
  export default {
	name: "Step_2",
	data() {
		return {
			modal: false,
			ready: false,
			scrolled: 0,
		}
	},
	components: {
		Modal,
	},
	mounted() {
		var element = document.getElementById('box');
		element.addEventListener('scroll', () => {
			this.scrolled = element.scrollTop;
		})
		window.CI360.init();
		setTimeout(() => {
			this.ready = true;
		}, 500)
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/about.sass'
</style>