<template>
	<div class="about">
		<div class="about__info" id="box">
			<router-link to="/" class="back-btn">
				<span class="arrow"></span>
				Вернуться
			</router-link>
			<div class="about__title">
				Решение стать потенциальным донором костного мозга
			</div>
			<div class="about__text">
				<p><strong>Если мы ищем родственную душу — друга или романтического партнёра, — то у нас есть длинный список критериев, которым соответствует не каждый человек. Сколько у вас было знакомств, прежде чем вы нашли кого‑то близкого? Пять, десять, пятьдесят?</strong></p>
				<p>А теперь представьте, что ваша родственная душа — это некто, имеющий такой же генетический набор, причем не во всех 46 хромосомах, а только <br>в одной — в 6-й. И даже в таком случае, чтобы найти его, вам пришлось <br>бы сходить на 100 000 свиданий. Кажется, это нереально. Но людям, <br>у которых диагностировали онкогематологическое заболевание, именно <br>так и приходится действовать. Им может помочь только пересадка костного мозга (КМ) или гемопоэтических стволовых клеток (ГСК), и приходится искать своего донора и надеяться, что он появится до того, как будет слишком поздно.</p>
				<p>Только подумайте, у вас есть генетический близнец: это человек, <br>с которым совпадает часть генотипа. Если он заболеет, то помочь ему можете только вы и ещё несколько человек на всей планете. Но проблема в том, <br>что в России мало доноров состоит в регистре — электронной базе данных, содержащей обезличенную информацию о генах потенциальных доноров костного мозга. Около 90% неродственных трансплантаций КМ и ГСК <br>в России осуществляются сейчас из зарубежных регистров. А доступ <br>к ним затруднен и дорого стоит.</p>
				<p>После вступления в регистр доноров КМ и ГСК ваш шанс стать реальным донором составляет менее 1%. Но это ответственный шаг, который нельзя совершать под влиянием порыва. Ведь помощи с нетерпением будет ждать ваш генетический близнец. Предлагаем узнать, какой путь проходит донор, который спасает жизнь своей идеальной генетической половинки.</p>
			</div>
			<div class="about__bottom">
				<div class="about__subtitle">Кто может стать донором?</div>
				<div class="about__row step1">
					<div class="about__row-column">
						<div class="about__row-head">
							<div class="about__row-large-text">от</div>
							<div class="about__row-number">18</div>
							<div class="about__row-large-text">до</div>
							<div class="about__row-number">45</div>
							<div class="about__row-large-text">лет</div>
						</div>
						<div class="about__row-text">возраст потенциального донора</div>
						<div class="about__row-block">Гражданин РФ или с регистрацией в РФ более 6 месяцев. <br>Без хронических заболеваний <br>В целом противопоказания для вступления аналогичны противопоказаниям к донорству крови и ее компонентов</div>
					</div>
					<div class="about__row-column">
						<ul class="about__row-list">
							<p class="about__row-text">Абсолютные противопоказания:</p>
							<li>ВИЧ-инфекция</li>
							<li>Сифилис</li>
							<li>Вирусные гепатиты</li>
							<li>Туберкулез</li>
							<li>Болезни крови</li>
							<li>Онкологические заболевания</li>
							<li>Психические расстройства и др.</li>
						</ul>
					</div>
				</div>
				<router-link to="/type" class="about__link" @click.native="sendEvent('reshenie_proiti')">СЛЕДУЮЩИЙ ШАГ: пройти типирование</router-link>
			</div>
		</div>
		<div class="about__interactive about__interactive--pink about__interactive--step1">
			<div class="about__image">
				<div
					class="cloudimage-360"
					v-show="ready"
					data-folder="/DNA23/"
					data-image-list-x='[
						"image-1.png",
						"image-2.png",
						"image-3.png",
						"image-4.png",
						"image-5.png",
						"image-6.png",
						"image-7.png",
						"image-8.png",
						"image-9.png",
						"image-10.png",
						"image-11.png",
						"image-12.png",
						"image-13.png",
						"image-14.png",
						"image-15.png",
						"image-16.png",
						"image-17.png",
						"image-18.png",
						"image-19.png",
						"image-20.png",
						"image-21.png",
						"image-22.png",
						"image-23.png",
						"image-24.png",
						"image-25.png",
						"image-26.png",
						"image-27.png",
						"image-28.png",
						"image-29.png",
						"image-29.png",
						"image-28.png",
						"image-27.png",
						"image-26.png",
						"image-25.png",
						"image-24.png",
						"image-23.png",
						"image-22.png",
						"image-21.png",
						"image-20.png",
						"image-19.png",
						"image-18.png",
						"image-17.png",
						"image-16.png",
						"image-15.png",
						"image-14.png",
						"image-13.png",
						"image-12.png",
						"image-11.png",
						"image-10.png",
						"image-9.png",
						"image-8.png",
						"image-7.png",
						"image-6.png",
						"image-5.png",
						"image-4.png",
						"image-3.png",
						"image-2.png",
						"image-1.png"
					]'
					data-autoplay
				></div>
			</div>
			<div class="about__label about__label--large">
				<span class="about__label-text">У ВАС ЕСТЬ ГЕНЕТИЧЕСКИЙ БЛИЗНЕЦ</span>
			</div>
		</div>
		<a href="javascript:void(0)" class="about__myth" @click="modal = true" :class="{scrolled: scrolled > 100, 'about__myth--pink': scrolled < 100, 'about__myth--green': scrolled > 99}">
			<span class="about__myth-text"><span><strong>миф</strong> <br>костный мозг находится <br>в позвоночнике <br><strong class="underline">УЗНАТЬ БОЛЬШЕ</strong></span></span>
		</a>
		<Modal v-if="modal" @close="modal = false"></Modal>
	</div>
</template>

<script>
  import Modal from '@/components/Popup_1.vue'
  export default {
	name: "Step_1",
	data() {
		return {
			modal: false,
			ready: false,
			scrolled: 0,
		}
	},
	components: {
		Modal,
	},
	mounted() {
		var element = document.getElementById('box');
		element.addEventListener('scroll', () => {
			this.scrolled = element.scrollTop;
		})
		window.CI360.init();
		setTimeout(() => {
			this.ready = true;
		}, 500)
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/about.sass'
</style>