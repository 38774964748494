<template>
	<div class="about">
		<div class="about__info" id="box">
			<router-link to="/activation" class="back-btn">
				<span class="arrow"></span>
				Вернуться
			</router-link>
			<div class="about__title">
				Процедура донации
			</div>
			<div class="about__text">
				<p><strong>Это кульминация истории, самый важный и ответственный этап <br>для вас — сдача стволовых клеток.</strong></p>
				<p>Обычно донацию назначают через две-три недели после полного обследования. Донор вновь едет в трансплантационный центр, и эту дорогу <br>с проживанием и питанием, опять же, оплачивает благотворительный фонд или регистр. Количество дней, которые придется провести в центре, <br>зависит от того, каким способом вы будете сдавать костный мозг. Выбор способа зависит от решения донора.</p>
				<p>Первый способ — метод афереза: при нем стволовые клетки забирают <br>через периферийную вену. За пять дней до процедуры донору делают подкожные уколы препарата, благодаря которому стволовые клетки попадают в общий кровоток. Процедура длится в среднем пять часов, кровь донора проходит в это время через специальный прибор — клеточный сепаратор. После этого донор может в тот же день ехать домой.</p>
				<p>Второй способ — метод эксфузии: это забор непосредственно костного <br>мозга из тазовой кости под общим наркозом. Плюс процедуры в том, что она не требует предварительной подготовки, кроме, пожалуй, ограничения в питье и приеме пищи за 12 часов до донации. Она безболезненна и длится всего один-два часа. При анестезии могут возникнуть незначительные побочные эффекты, но врачи их тщательно контролируют. После процедуры врачи наблюдают донора сутки и отпускают домой.</p>
				<p>Параллельно в это время происходит подготовка пациента <br>к трансплантации костного мозга. День ноль — это день трансплантации. <br>Если вас смущают возможные неприятные ощущения во время забора костного мозга, то подумайте, что вашему генетическому близнецу <br>предстоит тяжелое физическое испытание в течение нескольких <br>недель. Через месяц станет ясно, прижился ли ваш костный мозг.</p>
			</div>
			<div class="about__bottom">
				<div class="about__row step5">
					<div class="about__row-column">
						<div class="about__row-title">Способы сдачи <br>костного мозга</div>
						<div class="about__item">
							<div class="about__item-top">
								<div class="about__row-number">5<small>%</small></div>
								<div class="about__item-text">метод эксфузии</div>
							</div>
							<div class="about__item-line green"></div>
						</div>
						<div class="about__item">
							<div class="about__item-top">
								<div class="about__row-number">95<small>%</small></div>
								<div class="about__item-text">метод афереза</div>
							</div>
							<div class="about__item-line violet"></div>
						</div>
					</div>
					<div class="about__row-column">
						<div class="about__item with-icon">
							<div class="about__item-top">
								<div class="about__item-icon">
									<img src="@/assets/img/clock.svg" alt="">
								</div>
								<div class="about__row-number">72</div>
								<div class="about__item-text">часа</div>
							</div>
							<div class="about__item-info">максимальный срок годности донорских гемопоэтических стволовых клеток</div>
						</div>
						<div class="about__item with-icon">
							<div class="about__item-top">
								<div class="about__item-icon">
									<img src="@/assets/img/clock.svg" alt="">
								</div>
								<div class="about__row-number">3</div>
								<div class="about__item-text">КАЖДЫЕ <br>МИНуты</div>
							</div>
							<div class="about__item-info">у одного человека диагностируют рак крови</div>
						</div>
					</div>
				</div>
				<div class="about__text">
					<p>Если до процедуры донации путь у всех доноров одинаковый, то после нее <br>вы окажетесь на развилке: знакомиться или нет с вашей идеальной половинкой? Кто-то выбирает сохранение анонимности, но для <br>большинства важна встреча с реципиентом. А какое решение примете вы?</p>
				</div>
				<div class="about__links">
					<router-link to="/meet" class="about__link small" @click.native="sendEvent('donacia_recipientom')">Встретиться с реципиентом</router-link>
					<router-link to="/miracle" class="about__link small pink" @click.native="sendEvent('donacia_vstrechatsa')">не встречаться</router-link>
				</div>
			</div>
		</div>
		<div class="about__interactive about__interactive--solid">
			<div class="about__image about__image--large">
				<div
					class="cloudimage-360"
					data-index-zero-base="2"
					data-folder="/BLOOD/"
					data-image-list-x='[
						"bld_00.png",
						"bld_01.png",
						"bld_02.png",
						"bld_03.png",
						"bld_04.png",
						"bld_05.png",
						"bld_06.png",
						"bld_07.png",
						"bld_08.png",
						"bld_09.png",
						"bld_10.png",
						"bld_11.png",
						"bld_12.png",
						"bld_13.png",
						"bld_14.png",
						"bld_15.png",
						"bld_16.png",
						"bld_17.png",
						"bld_18.png",
						"bld_19.png",
						"bld_20.png",
						"bld_21.png",
						"bld_22.png",
						"bld_23.png",
						"bld_24.png",
						"bld_25.png",
						"bld_26.png",
						"bld_27.png",
						"bld_28.png",
						"bld_29.png",
						"bld_29.png",
						"bld_28.png",
						"bld_27.png",
						"bld_26.png",
						"bld_25.png",
						"bld_24.png",
						"bld_23.png",
						"bld_22.png",
						"bld_21.png",
						"bld_20.png",
						"bld_19.png",
						"bld_18.png",
						"bld_17.png",
						"bld_16.png",
						"bld_15.png",
						"bld_14.png",
						"bld_13.png",
						"bld_12.png",
						"bld_11.png",
						"bld_10.png",
						"bld_09.png",
						"bld_08.png",
						"bld_07.png",
						"bld_06.png",
						"bld_05.png",
						"bld_04.png",
						"bld_03.png",
						"bld_02.png",
						"bld_01.png"
					]'
					v-show="ready"
					data-autoplay
				></div>
			</div>
			<div class="about__label">
				<span class="about__label-text">ЭТО <br>БЕЗОПАСНО</span>
			</div>
		</div>
		<a href="javascript:void(0)" class="about__myth" @click="modal = true" :class="{scrolled: scrolled > 100, 'about__myth--pink': scrolled < 100, 'about__myth--violet': scrolled > 99}">
			<span class="about__myth-text"><span><strong>миф</strong>  <br>донорство костного мозга — <br>это больно и опасно <br><strong class="underline">УЗНАТЬ БОЛЬШЕ</strong></span></span>
		</a>
		<Modal v-if="modal" @close="modal = false"></Modal>
	</div>
</template>

<script>
  import Modal from '@/components/Popup_5.vue'
  export default {
	name: "Step_5",
	data() {
		return {
			modal: false,
			ready: false,
			scrolled: 0,
		}
	},
	components: {
		Modal,
	},
	mounted() {
		var element = document.getElementById('box');
		element.addEventListener('scroll', () => {
			this.scrolled = element.scrollTop;
		})
		window.CI360.init();
		setTimeout(() => {
			this.ready = true;
		}, 500)
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/about.sass'
</style>