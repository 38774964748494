<template>
	<div class="popup">
		<div class="popup__bg"></div>
		<div class="popup__window popup__window--violet">
			<a href="javascript:void(0)" @click="$emit('close')" class="popup__close"></a>
			<div class="popup__title desk"><strong>Существует миф, что</strong> <br>Доноры костного мозга должны быть <br>совместимы по группе крови</div>
			<div class="popup__title mob"><strong>Существует миф, что</strong> <br>Доноры костного мозга <br>должны быть совместимы <br>по группе крови</div>
			<div class="popup__text">Зачастую люди так думают, потому что путают переливание крови и трансплантацию костного <br>мозга. Хотя эти процессы похожи, но при трансплантации костного мозга группа крови и резус- <br>фактор не имеют значения. Дело в том, что перед трансплантацией пациенту проводят <br>химиотерапию, которая должна уничтожить его больной костный мозг. В течение 2,5–3 месяцев <br>после пересадки у него циркулируют две группы крови, но затем — если костный мозг <br>прижился — группа и резус-фактор крови становятся как у донора. В это трудно поверить, <br>но в жилах постороннего человека буквально будет течь ваша кровь.</div>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Popup_3",
	mounted() {
		this.sendEvent('para_mif');
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/popup.sass'
</style>