import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '../views'
import Solutions from '../views/solutions.vue'
import Type from '../views/type.vue'
import Search from '../views/Search.vue'
import Activation from '../views/Activation.vue'
import Donation from '../views/Donation.vue'
import Meet from '../views/Meet.vue'
import Modals from '../views/modals.vue'
import Steps from '../views/steps.vue'
import Miracle from '../views/Miracle.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    meta: {
      title: 'Главная',
    },
  },
  {
    path: '/solution',
    component: Solutions,
    meta: {
      title: 'Решение стать донором',
    },
  },
  {
    path: '/type',
    component: Type,
    meta: {
      title: 'Типирование',
    },
  },
  {
    path: '/search',
    component: Search,
    meta: {
      title: 'Поиск пары',
    },
  },
  {
    path: '/activation',
    component: Activation,
    meta: {
      title: 'Активация',
    },
  },
  {
    path: '/donation',
    component: Donation,
    meta: {
      title: 'Донация',
    },
  },
  {
    path: '/meet',
    component: Meet,
    meta: {
      title: 'Встреча',
    },
  },
  {
    path: '/miracle',
    component: Miracle,
    meta: {
      title: 'Совершить чудо',
    },
  },
  {
    path: '/modals',
    component: Modals,
    meta: {
      title: 'Модалки',
    },
  },
  {
    path: '/steps',
    component: Steps,
    meta: {
      title: 'Шаги',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

router.beforeEach((to, from, next) => {
  setTimeout(() => {
    next () 
  }, 100)
})

export default router
