<template>
	<div class="about">
		<div class="about__info">
			<div class="about__info-top about__info-top--green">
				<router-link to="/solution" class="back-btn">
					<span class="arrow"></span>
					Вернуться
				</router-link>
				<div class="about__title">
					HLA-типирование
				</div>
				<div class="about__text">
					<p><strong>Однажды утром вы проснетесь и подумаете: «Сегодня подходящий день для того, чтобы совершить добрый поступок». Как и все большие дела, он начинается с мелочи.</strong></p>
					<p>Для вступления в регистр доноров костного мозга надо обратиться с паспортом в рекрутинговый центр. Там вам предложат заполнить анкету-согласие (так вы подтверждаете, что вы вступаете в регистр добровольно), указать контактные данные и сдать кровь на HLA-типирование — именно оно позволит в будущем найти вашего генетического близнеца.</p>
					<p>Дело в том, что HLA-антигены, или система тканевой совместимости, — это белки, которые работают как система распознавания свой — чужой: если они обнаруживают чужеродные клетки в организме, то запускается иммунный ответ, обеспечивающий их уничтожение. Проблема в том, что иммунная система может посчитать клетки пересаживаемого костного мозга вражескими и атаковать их. Начнется процесс отторжения, а это опасное для жизни пациента состояние.</p>
					<p>Именно поэтому для успешной трансплантации необходимо, чтобы донор и пациент совпадали по HLA-системе почти на 100%. Тогда иммунная система посчитает их своими, и процесс уничтожения не включится. Синтез белков HLA-системы определяется генами, которые расположены в 6-й хромосоме. <br>У большинства людей, у вас в том числе, есть «близнец» — человек, с которым гены в этой хромосоме идентичны.</p>
				</div>
				<div class="about__interactive about__interactive--green">
					<div class="about__image">
						<div
							class="cloudimage-360"
							data-index-zero-base="2"
							data-folder="/MICRO/"
							data-image-list-x='[
								"Micro_00.png",
								"Micro_01.png",
								"Micro_02.png",
								"Micro_03.png",
								"Micro_04.png",
								"Micro_05.png",
								"Micro_06.png",
								"Micro_07.png",
								"Micro_08.png",
								"Micro_09.png",
								"Micro_10.png",
								"Micro_11.png",
								"Micro_12.png",
								"Micro_13.png",
								"Micro_14.png",
								"Micro_15.png",
								"Micro_16.png",
								"Micro_17.png",
								"Micro_18.png",
								"Micro_19.png",
								"Micro_20.png",
								"Micro_21.png",
								"Micro_22.png",
								"Micro_23.png",
								"Micro_24.png",
								"Micro_25.png",
								"Micro_26.png",
								"Micro_27.png",
								"Micro_28.png",
								"Micro_29.png",
								"Micro_29.png",
								"Micro_28.png",
								"Micro_27.png",
								"Micro_26.png",
								"Micro_25.png",
								"Micro_24.png",
								"Micro_23.png",
								"Micro_22.png",
								"Micro_21.png",
								"Micro_20.png",
								"Micro_19.png",
								"Micro_18.png",
								"Micro_17.png",
								"Micro_16.png",
								"Micro_15.png",
								"Micro_14.png",
								"Micro_13.png",
								"Micro_12.png",
								"Micro_11.png",
								"Micro_10.png",
								"Micro_09.png",
								"Micro_08.png",
								"Micro_07.png",
								"Micro_06.png",
								"Micro_05.png",
								"Micro_04.png",
								"Micro_03.png",
								"Micro_02.png",
								"Micro_01.png"
							]'
							data-autoplay
						></div>
					</div>
					<div class="about__label">
						<span class="about__label-text">ВСЕГО ЛИШЬ АНАЛИЗ</span>
					</div>
				</div>
			</div>
			<div class="about__content">
				<div class="about__text">
					<p>HLA-типирование — это своего рода перепись генов, именно с его помощью происходит подбор пары донор — реципиент. Для типирования у вас возьмут кровь из вены (одну пробирку до 10 мл), и это практически безболезненно.</p>
					<p>Специальной подготовки не требуется, поэтому прийти можно в любое время. Заполнение анкеты и сдача крови займут максимум час. И главное, оплачивать ничего не нужно.</p>
					<p>Когда проведут HLA-типирование вашей крови, информацию о генотипе занесут в базу регистра, а вам сообщат об этом. Доступ к регистру есть у специалистов трансплантационных центров. Однако даже им информация доступна в обезличенном виде, то есть ваши личные и контактные данные будут храниться отдельно, чтобы обеспечивать вашу конфиденциальность. Дальше остается только ждать.</p>
				</div>
				<a href="javascript:void(0)" class="about__myth about__myth--green rellax-h"  :class="{'go': scrolled > 70}" @click="modal = true" data-rellax-vertical-speed="0" data-rellax-horizontal-speed="-0.2" data-rellax-vertical-scroll-axis="x"  data-rellax-percentage="0.48">
					<span class="about__myth-text"><span><strong>миф</strong> <br>донором может стать только <br>близкий родственник <br><strong class="underline">УЗНАТЬ БОЛЬШЕ</strong></span></span>
				</a>
				<div class="about__bottom">
					<div class="about__row step2">
						<div class="about__row-column">
							<div class="about__row-title">несоответствие</div>
							<div class="about__row-image about__row-image--1">
								<img src="@/assets/img/pazzles-1.svg" alt="">
							</div>
						</div>
						<div class="about__row-column">
							<div class="about__row-title">HLA-соответствие</div>
							<div class="about__row-image about__row-image--2">
								<img src="@/assets/img/pazzles-2.svg" alt="">
							</div>
						</div>
					</div>
					<router-link to="/search" class="about__link" @click.native="sendEvent('tipirovanie_naiti')"><span>СЛЕДУЮЩИЙ ШАГ: <br>найти пару</span></router-link>
				</div>
			</div>
		</div>
		<Modal v-if="modal" @close="modal = false"></Modal>
	</div>
</template>

<script>
  import Modal from '@/components/Popup_2.vue'
  export default {
	name: "Step_mob_2",
	data() {
		return {
			modal: false,
			scrolled: 0,
		}
	},
	components: {
		Modal,
	},
	mounted() {
		window.CI360.init();
		document.addEventListener('scroll', () => {
			var h = document.documentElement, 
				b = document.body,
				st = 'scrollTop',
				sh = 'scrollHeight';

			this.scrolled = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
		})
	}
  }
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/about.sass'
</style>