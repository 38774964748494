<template>
  <div class="page">
    <Header />
    <div class="desktop">
      <Content />
    </div>
    <div class="mobile">
      <Content_mob />
    </div>
  </div>
</template>

<script>
  import Header from '@/components/Header'
  import Content from '@/components/Step_6'
  import Content_mob from '@/components/Step_mob_6'
  export default {
    name: "Steps",
    components: {
      Header,
      Content,
      Content_mob,
    }
  }
</script>

<style lang="sass">
  @import '@/assets/sass/functions.sass'
  @import '@/assets/sass/about.sass'
</style>