<template>
  <div class="page">
    <Header />
    <Step_mob_6></Step_mob_6>
  </div>
</template>

<script>
  import Header from '@/components/Header'
  import Step_mob_6 from '@/components/Step_mob_6'
  export default {
    name: "Steps",
    components: {
      Step_mob_6,
      Header,
    }
  }
</script>

<style lang="sass">
  @import '@/assets/sass/functions.sass'
  @import '@/assets/sass/about.sass'
</style>