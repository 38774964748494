<template>
  <div class="page">
    <Popup_6></Popup_6>
  </div>
</template>

<script>
  import Popup_6 from '@/components/Popup_6'
  export default {
    name: "Modals",
    components: {
      Popup_6,
    }
  }
</script>

<style lang="sass">
  @import '@/assets/sass/functions.sass'
  @import '@/assets/sass/about.sass'
</style>